import api, { authorizedApi } from '../../utils/api';

export const savePayload = (data) => {
  return api().post(`/participant/moby/confirmation`, data);
};

export const createInvitation = (token, data) => {
  return authorizedApi(token).post(`/participant/create/moby/invitation`, data);
};

export const addUserConcent = (token, data) => {
  return authorizedApi(token).post(`/participant/moby/img/confirm`, data);
};

export const getDLDetails = (token, uuid, notVerified = false) => {
  let url = `/participant/moby/transaction/${uuid}`;
  if (notVerified) url += `?not_verified=${true}`;
  return authorizedApi(token).get(url);
};

export const getBankDetails = (token, participantId, accountNum) => {
  return authorizedApi(token).get(
    `/participant/bank/detail/${participantId}?account_number=${accountNum}`
  );
};